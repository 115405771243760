
.bento-container { 
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    padding-top: 5rem;
    margin: 1rem 5% 0 5%;
  }
  
  .content {
    /* background: linear-gradient(to bottom, var(--primary), var(--secondary)); */
    /* filter: brightness(0.5); */
    /* border-color: aliceblue;
    border-style: solid; */
  
  
    padding: 10px; 
    text-align: center;
    margin-top: 3rem;
  } 
  
  
  .T1 { grid-area: 1 / 1 / 2 / 2; }
  .T2 {
    grid-area: 1 / 2 / 2 / 3;
    padding-top: 5rem;
 }
  
  .T1-text-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 0.5fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
  
  .T1-Main { 
    grid-area: 1 / 1 / 2 / 3;
  }
  
  .T1-Main p {
    background-image: linear-gradient(45deg, var(--secondary), var(--accent));
    background-size: 100%;
    background-clip: text;
    text-align: left;
    -webkit-text-fill-color: transparent;
    font-size: 4.5em;
    font-family: 'Oswald', sans-serif;
  }
  
  .T1-Sub {
     grid-area: 2 / 1 / 3 / 3; 
     padding-top: 1rem;
  }
  
  .T1-Sub p {
    font-size: 1em;
    filter: brightness(0.75);
    text-align: left;
    font-family: 'Oswald', sans-serif;
    padding-bottom: 0.5rem;
  }
  

  .divider {
    border-top: 1px solid var(--accent);
    width: 100%;
  }



  .T2 img {
    /* width: 50%;
    height: 20%; */
    position: absolute;
    left: 0%;
    transform: translateY(-140%);
    animation: linear infinite;
    animation-name: move-right;
    animation-duration: 10s;
  }
  
  @keyframes move-right {
    0% {
      left: 0%;
    }
    100% {
      left: 100%;
    }
  }


  