@import 'variables.css';


.linkButton-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;
    padding-right: 3%;
}

.button-1 a{
    transition: filter 0.2s ease-in-out;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.button-1 img {
    margin-right: 0.5rem;
    margin-top: 0.5rem;
    height: 1.75rem;
    width: 1.75rem;
}

.button-1 p {
    font-size: 1.75rem;
}


.button-1 a:hover {
    filter:brightness(0.5)
}
