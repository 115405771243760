
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
@import 'variables.css';



body {
  background-color: var(--background);
  overflow-x: hidden;
}

.header {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  top: 0;
  width: 100%;
  border-bottom: 1px solid var(--primary);
  background-color: var(--background);
  z-index: 9997;
}


.logo-container {
  padding-left: 5%;
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.logo-container img {
  width: 50px;
  height: 50px;
  margin-right: 1%;
}

p {
  font-family: 'Oswald', sans-serif;
  font-size: 1.5em;
  margin: 0;
  padding: 0;
  color: var(--text);
} 

h1 {
  font-family: 'Oswald', sans-serif;
  font-size: 2em;
  margin: 0;
  padding: 0;
  color: var(--text);
}

